<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="登陆账号" prop="mobile">
        <a-input v-model="form.mobile" placeholder="登陆账号" />
      </a-form-model-item>

      <a-form-model-item label="重置密码" prop="passwd">
        <a-input v-model="form.passwd" placeholder="请输入重置的密码" />
      </a-form-model-item>
      <a-form-model-item label="姓名" prop="nickname">
        <a-input v-model="form.nickname" placeholder="请输入姓名" />
      </a-form-model-item>
      <a-form-model-item label="图片" prop="avatar" >
        <template>
          <div class="clearfix">
            <a-upload action="http://www.daccess.com.sg:7070/admin-api/common/upload" list-type="picture-card"
              :file-list="fileList" @preview="handlePreview" @change="handleChange">
              <div v-if="fileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="form.avatar" />
            </a-modal>
          </div>
        </template>
      </a-form-model-item>
      <a-form-model-item label="工号" prop="workNum">
        <a-input v-model="form.workNum" placeholder="请输入工号" />
      </a-form-model-item>
      <a-form-model-item label="职务" prop="office">
        <a-input v-model="form.office" placeholder="请输入职务" />
      </a-form-model-item>
      <a-form-model-item label="部门" prop="dept">
        <a-input v-model="form.dept" placeholder="请输入部门" />
      </a-form-model-item>
      <a-form-model-item label="性别" prop="sex">
        <a-radio-group v-model="form.sex" button-style="solid">
          <a-radio-button :value="0">保密</a-radio-button>
          <a-radio-button :value="1">男</a-radio-button>
          <a-radio-button :value="2">女</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="用户权限" prop="status">
        <a-radio-group v-model="form.type" button-style="solid">
          <a-radio-button :value="2">无限制</a-radio-button>
          <a-radio-button :value="0">只能用NFC开门</a-radio-button>
          <a-radio-button :value="1">只能用APP开门</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <!-- <a-form-model-item label="生日" prop="birthday">
        <a-input v-model="form.birthday" placeholder="请输入生日" />
      </a-form-model-item> -->

      <a-form-model-item label="生日" prop="status">
        <a-date-picker @change="onChange" v-model="form.birthday" />
      </a-form-model-item>
      <a-form-model-item label="是否管理员" prop="isAdmin">
        <a-radio-group v-model="form.isAdmin" button-style="solid">
          <a-radio-button :value=false>否</a-radio-button>
          <a-radio-button :value=true>是</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="用户状态" prop="status">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button :value="0">禁用</a-radio-button>
          <a-radio-button :value="1">正常</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-button type="primary" :loading="submitLoading" @click="show">绑定设备</a-button>
      <template>
        <a-list item-layout="horizontal" :data-source="checkList">
          <a-list-item slot="renderItem" slot-scope="item, index">
            已绑设备名：{{ item.doorName }} 设备编码：{{ item.devCode }}
          </a-list-item>
        </a-list>

      </template>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <!-- 设备列表 -->
    <a-modal title=绑定设备 :visible="addressVisible" width="1300px" footer="" @cancel="addressVisible = false">
      <address-list ref="addressPage" @ok="changeAddress" />
    </a-modal>
  </a-drawer>
</template>

<script>
import { getUser, addUser, updateUser } from '@/api/user/user'
import AddressList from './addressList'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    AddressList
  },
  data() {
    return {
      ids: [],
      addressVisible: false,
      submitLoading: false,
      formTitle: '',
      checkList: [],
      fileList: [
      ],
      // 表单参数
      form: {
        id: null,
        checkList: [],
        mobile: null,
        avatar:null,
        passwd: null,

        dept:null,
        avatar: null,

        nickname: null,

        sex: null,

        birthday: null,

        userSign: null,

        isNotice: null,

        status: 1,

        createTime: null,

        remark: null,

        idCard: null,
        type: 2,
        workNum: null,
        office: null,
        dept:null,
        isAdmin:false
        

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        mobile: [
          { required: true, message: '用户名/手机号，用于登录不能为空', trigger: 'blur' }
        ],
        avatar: [
          { required: true, message: '头像链接不能为空', trigger: 'blur' }
        ],
        nickname: [
          { required: true, message: '姓名不能为空', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '性别不能为空', trigger: 'change' }
        ],

        isNotice: [
          { required: true, message: '是否推送不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '用户状态不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    onChange(e) {
      console.log(e)
      console.log(this.form.birthday, '>>>>>>>>>>>>>>>>>>')
    },
    changeAddress(list,ids) {
      // console.log("传过来的" + list)
      this.checkList = list
      this.devIdList = ids
      this.addressVisible = false;
      console.log("传过来的" + this.devIdList)
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    show() {
      this.addressVisible = true;
      this.$nextTick(() => {
        // this.form.userId=this.form.userResultList.map(e=> {return e.id});
        console.log("form", this.form)
        this.$refs.addressPage.init(this.form.deviceResults.map(e => { return e.id }))
      })
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.checkList = []
      this.form = {
        id: null,
        avatar:null,
        devIdList: [],
        mobile: null,
        passwd: null,
        avatar: null,
        nickname: null,
        sex: null,
        birthday: null,
        userSign: null,
        isNotice: null,
        status: 1,
        createTime: null,
        remark: null,
        idCard: null,
        checkList: [],
        workNum: null,
        office: null,
        dept:null,
        isAdmin:false
      }
      this.$nextTick(() => {
        // this.$refs.form.resetFields()

      })
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.formType = 1
      this.open = true
      this.checkList = []
      this.form.checkList = []
      this.formTitle = '添加',
      this.fileList=[]
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUser({ "id": id }).then(response => {
        this.form = response.data
        this.checkList = response.data.deviceResults
        console.log(111111)
        if(!this.checkList){
          this.checkList=[]
        }
        console.log(33333)
        this.devIdList= this.checkList.map(item => item.id)
        this.open = true
        this.formTitle = '修改'
        this.fileList[0] = {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: response.data.avatar
        }
      })
    },

    handleCancel() {
      this.previewVisible = false;
    },

    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange(info) {
      console.log(info)
      this.fileList = info.fileList
      this.fileList.forEach(item => {
        if (item.status === 'done') {
          if (item.response.resultCode !== 1) {
            // item.status = 'error'
            // item.url = undefined
          } else {
            item.url = `${this.url_}${item.response.data}`
          }
          this.form.avatar=item.response.data
        }
      })
   console.log(this.form.imgUrl)
      this.fileList = fileList;
      this.fileList.forEach(element => {
        console.log("文件上传" + element.response)
        this.form.imgUrl=element.url
      });
     },
    /** 提交按钮 */
    submitForm: function () {
      console.log("提交" + this.devIdList)
      this.form.devIdList = this.devIdList
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
